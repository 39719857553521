import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import {Navbar, Nav, NavbarBrand, NavItem} from 'reactstrap';

const Header = ({ siteTitle }) => (
  <Navbar className="px-0">
    <NavbarBrand><h1><Link to="/">{siteTitle}</Link></h1></NavbarBrand>
    <Nav>
      <NavItem><Link to="/blog">Thoughts</Link><span className="text-primary"> &#9733;&nbsp;</span></NavItem>
      <NavItem><Link to="/books">Reading List</Link><span className="text-primary"> &#9733;&nbsp;</span></NavItem>
      <NavItem><Link to="/contact">Contact</Link></NavItem>
    </Nav>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
