import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import SEO from '../components/seo'
import './custom.scss';
import { Container, Button, Row } from "reactstrap";
import Col from "reactstrap/es/Col";
import BraveLogo from '../images/brave-logo.svg';
import TwitterLogo from '../images/twitter-logo.svg';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container>
          <Row>
            <Col>
              <Header siteTitle={data.site.siteMetadata.title} />
              <SEO title={data.site.siteMetadata.title} />
              {children}
            </Col>
          </Row>
          <Row className="py-4 border-top-dashed">
            <Col sm="6" md="3"className="mb-3">
              <Button className="btn-block" href="http://twitter.com/marcodamm"><img className="icon-btn" src={TwitterLogo} alt="Twitter Logo"/> Twitter</Button>
            </Col>
            <Col sm="6" md="3" className="mb-3">
              <Button className="btn-block" href="https://brave.com/ykr033"><img className="icon-btn" src={BraveLogo} alt="Brave Browser Logo"/> Get Brave</Button>
            </Col>
          </Row>
        </Container>
     </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
